import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex mx-auto my-12 lg:w-11/12" }
const _hoisted_4 = { class: "flex-1 ml-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarNavigationLink = _resolveComponent("SidebarNavigationLink")!
  const _component_SidebarNavigationGroup = _resolveComponent("SidebarNavigationGroup")!
  const _component_SidebarNavigation = _resolveComponent("SidebarNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SidebarNavigation, null, {
              default: _withCtx(() => [
                _createVNode(_component_SidebarNavigationGroup, {
                  title: _ctx.$t('platform.settings.my-account')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SidebarNavigationLink, {
                      to: "/settings/profile",
                      icon: "user"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.profile')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_SidebarNavigationLink, {
                      to: "/settings/account-setting",
                      icon: "pin"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.account')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["title"]),
                _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationGroup, {
                  "data-dusk": "my-organisation-header",
                  title: _ctx.$t('platform.settings.my-organisation')
                }, {
                  default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                      to: "/settings/users",
                      icon: "users-multiple"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.users')), 1)
                      ]),
                      _: 1
                    })), [
                      [_directive_allow, 'user:read']
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                      to: "/settings/escalation-emails-setting",
                      icon: "edit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.settings')), 1)
                      ]),
                      _: 1
                    })), [
                      [_directive_allow, 'org-unit:read']
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                      to: "/settings/roles",
                      icon: "circuit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.roles-permissions')), 1)
                      ]),
                      _: 1
                    })), [
                      [_directive_allow, 'role:read']
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                      to: "/settings/devices",
                      icon: "workstation"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.devices')), 1)
                      ]),
                      _: 1
                    })), [
                      [_directive_allow, 'device:read']
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                      to: "/settings/preferences",
                      icon: "preferences1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.preferences')), 1)
                      ]),
                      _: 1
                    })), [
                      [_directive_allow, 'org-unit-preference:update']
                    ])
                  ]),
                  _: 1
                }, 8, ["title"])), [
                  [_directive_allow, 'org-unit-setting:read']
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_Transition, {
                    name: "fade",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        organisation: _ctx.currentOrganisation,
                        onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateOrganisation($event)))
                      }, null, 8, ["organisation"]))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}