
import { Vue, Options } from 'vue-class-component';
import { SidebarNavigation, SidebarNavigationGroup, SidebarNavigationLink } from '@/lib/components';
import SettingsPageLayout from '@/lib/layouts/SettingsPageLayout.vue';
import { useSessionStore } from '@/stores/session.store';

@Options({
  components: {
    SettingsPageLayout,
    SidebarNavigation,
    SidebarNavigationGroup,
    SidebarNavigationLink
  }
})
export default class SettingsPage extends Vue {
  sessionStore = useSessionStore();

  get currentOrganisation() {
    return this.sessionStore.currentOrganisation;
  }

  updateOrganisation() {
    this.sessionStore.fetchCurrentUser();
  }
}
